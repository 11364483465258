import {
  ArrayLayoutProps,
  ControlElement,
  JsonFormsCellRendererRegistryEntry,
  JsonFormsRendererRegistryEntry,
  JsonSchema,
  Paths,
  createDefaultValue,
} from '@jsonforms/core'
import { DispatchCell, withJsonFormsArrayLayoutProps } from '@jsonforms/react'
import range from 'lodash/range'
import { MdAdd, MdDelete } from 'react-icons/md'

import { IconButton, OutlineButton } from 'src/components/Buttons'
import { FieldLabel } from 'src/components/Fields'

import { ErrorsLabel } from '../controls/ErrorsLabel'

const controlWithoutLabel = (
  scope: string,
  options?: {
    [key: string]: any
  },
): ControlElement => ({
  type: 'Control',
  scope: scope,
  label: false,
  options,
})

interface CellProps {
  path: string
  schema: JsonSchema
  uischema: ControlElement
  enabled: boolean
  renderers?: JsonFormsRendererRegistryEntry[]
  cells?: JsonFormsCellRendererRegistryEntry[]
}
function Cell({ uischema, ...props }: CellProps) {
  return <DispatchCell uischema={controlWithoutLabel('#', uischema.options)} {...props} />
}

interface RowProps {
  path: string
  schema: JsonSchema
  uischema: ControlElement
  enabled: boolean
  renderers?: JsonFormsRendererRegistryEntry[]
  cells?: JsonFormsCellRendererRegistryEntry[]
  deleteRow?: () => void
}
function Row({ deleteRow, ...props }: RowProps) {
  return (
    <div className="w-full flex flex-row items-center space-x-4">
      <div className="flex-grow">
        <Cell {...props} />
      </div>
      <IconButton className="w-8 h-8" icon={MdDelete} onClick={() => deleteRow?.()} />
    </div>
  )
}

function TableControl(props: ArrayLayoutProps) {
  const { path, schema, uischema, rootSchema, addItem, removeItems, label, data, enabled, renderers, cells, errors } =
    props
  const isEmptyTable = data === 0
  return (
    <div className="space-y-2">
      <ErrorsLabel errors={errors} />
      <FieldLabel>{label}</FieldLabel>
      {isEmptyTable ? (
        <div className="w-full flex justify-center">No data</div>
      ) : (
        range(data).map((index: number) => (
          <Row
            path={Paths.compose(path, `${index}`)}
            schema={schema}
            uischema={uischema}
            enabled={enabled}
            renderers={renderers}
            cells={cells}
            deleteRow={removeItems?.(path, [index])}
          />
        ))
      )}
      <div className="flex justify-center">
        <OutlineButton icon={MdAdd} onClick={addItem(path, createDefaultValue(schema, rootSchema))}>
          Add
        </OutlineButton>
      </div>
    </div>
  )
}

export const ArrayControlRenderer = (props: ArrayLayoutProps) => {
  const { visible } = props

  if (!visible) {
    return null
  }

  return <TableControl {...props} />
}

export default withJsonFormsArrayLayoutProps(ArrayControlRenderer)
