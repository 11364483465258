import { CellProps } from '@jsonforms/core'
import { withJsonFormsCellProps } from '@jsonforms/react'

import TextAreaControl from '../controls/TextAreaControl'

export function Cell(props: CellProps) {
  return <TextAreaControl {...props} />
}

export default withJsonFormsCellProps(Cell)
