import { Layout, LayoutProps } from '@jsonforms/core'
import { DispatchCell, withJsonFormsLayoutProps } from '@jsonforms/react'
import classNames from 'classnames'

interface ColumnLayout extends Layout {
  type: 'ColumnLayout'
}

const LayoutRenderer = (props: LayoutProps) => {
  const { label, uischema, data, ...otherProps } = props
  const columnLayout = uischema as ColumnLayout

  const numColumns = uischema.options?.columnLabels?.length || uischema.options?.numColumns || 1
  const columnLabels = uischema.options?.columnLabels

  const withoutLabel = (schema: any) => {
    if (!uischema.options?.hideLabels) {
      return schema
    }
    return { ...schema, label: false }
  }
  return (
    <div className="py-6">
      <div
        className={classNames('grid gap-x-2 gap-y-2 items-center', {
          'grid-cols-2': numColumns === 2,
          'grid-cols-3': numColumns === 3,
          'grid-cols-4': numColumns === 4,
          'grid-cols-5': numColumns === 5,
          'grid-cols-6': numColumns === 6,
          'grid-cols-1': numColumns <= 1 || numColumns > 6,
        })}
      >
        {columnLabels.map((l, i) => (
          <b key={`${l} - ${i}`}>{l}</b>
        ))}
        {columnLayout.elements.map((elUiSchema, idx) => (
          <DispatchCell key={idx} {...otherProps} uischema={withoutLabel(elUiSchema)} />
        ))}
      </div>
    </div>
  )
}

const LayoutWithJsonForms = withJsonFormsLayoutProps(LayoutRenderer)
export default LayoutWithJsonForms
