import { CellProps } from '@jsonforms/core'
import { withJsonFormsCellProps } from '@jsonforms/react'
import React from 'react'

import CountryControl from 'src/components/JsonForms/controls/CountryControl'

export function Cell(props: CellProps) {
  return <CountryControl {...props} />
}

export default withJsonFormsCellProps(Cell)
