import { ControlProps } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'

import { InputField } from 'src/components/Fields'
import { ErrorsLabel } from 'src/components/JsonForms/controls/ErrorsLabel'

const InputControl = ({
  data,
  label,
  path,
  schema,
  required,
  enabled,
  visible,
  errors,
  handleChange,
}: ControlProps) => (
  <InputField
    type={schema.format}
    label={label}
    value={data}
    isRequired={required}
    readOnly={!enabled}
    hidden={!visible}
    onChange={(e) => handleChange(path, e.target.value)}
    hasError={!!errors}
  >
    <ErrorsLabel errors={errors} />
  </InputField>
)

const InputControlWithJsonForms = withJsonFormsControlProps(InputControl)
export default InputControlWithJsonForms
