import { ControlProps } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'

import { ButtonGroup } from 'src/components/ButtonGroup'
import { FieldLabel } from 'src/components/Fields'
import { ErrorsLabel } from 'src/components/JsonForms/controls/ErrorsLabel'

const Control = ({ data, label, required, enabled, visible, handleChange, path, errors }: ControlProps) =>
  !visible ? null : (
    <div className="flex flex-row items-center justify-between py-2">
      <div className="ml-2">
        <FieldLabel isRequired={required}>{label}</FieldLabel>
        <ErrorsLabel errors={errors} />
      </div>
      <ButtonGroup
        options={[
          { id: 'yes', name: 'Yes' },
          { id: 'no', name: 'No' },
        ]}
        value={data ? 'yes' : 'no'}
        onSelectedChanged={(id) => handleChange(path, id === 'yes')}
        readOnly={!enabled}
      />
    </div>
  )

const ControlWithJsonForms = withJsonFormsControlProps(Control)
export default ControlWithJsonForms
