import { ControlProps } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { useEffect, useState } from 'react'

import { InputField, SelectField } from 'src/components/Fields'
import {
  useGetClassifierObject,
  useGetClassifierSubOptions,
  useGroupedHandlerRecyclingActivityOptions,
} from 'src/hooks'

import ArrayComponent from './ArrayComponent'

interface Activity {
  stage: string
  activity: string
  description: string
  subActivity: string
  subDescription: string
}

// Note: This component will need refactoring when custom array control support is implemented.
const ActivityControl = ({ data, path, required, enabled, visible, handleChange, errors }: ControlProps) => {
  const facilityRecyclingStages = useGroupedHandlerRecyclingActivityOptions()

  const getFacilityRecyclingStages = useGetClassifierSubOptions(
    'facilityRecyclingStages',
    'id',
    'name',
    'activities',
    'id',
    'name',
  )

  const getSubActivities = useGetClassifierSubOptions(
    'facilityRecyclingActivities',
    'id',
    'name',
    'subActivities',
    'id',
    'name',
  )
  const getActivity = useGetClassifierObject('facilityRecyclingActivities', 'id')

  const [activityOptions, setActivityOptions] = useState({})

  useEffect(() => {
    const newActivityOptions = {}
    data?.forEach((item: Activity, index: number) =>
      item.stage
        ? (newActivityOptions[index] = facilityRecyclingStages?.find((s) => s.value === item.stage)?.options || [])
        : [],
    )
    setActivityOptions(newActivityOptions)
  }, [data, facilityRecyclingStages, getFacilityRecyclingStages])

  const generateNewItem = (): Activity => ({
    stage: '',
    activity: '',
    description: '',
    subActivity: '',
    subDescription: '',
  })

  const renderItem = (item: Activity, index: number, updateItem: (newItem: Activity) => void) => {
    const selectedActivity = getActivity(Number(item.activity))
    const subActivities = getSubActivities(item.activity)
    const subActivityDescriptionRequired = subActivities?.find((o) => o.id === item.subActivity)?.descriptionRequired

    return (
      <div className="flex items-end gap-4 w-full">
        <SelectField
          className="flex-1"
          label="Stage"
          options={facilityRecyclingStages}
          value={item.stage}
          isRequired={required}
          readOnly={!enabled}
          hidden={!visible}
          onChange={(e) =>
            updateItem({
              ...item,
              stage: e.target.value,
              activity: '',
              description: '',
              subActivity: '',
              subDescription: '',
            })
          }
        />
        {activityOptions[index]?.length ? (
          <SelectField
            className="flex-1"
            label="Activity"
            value={item.activity}
            options={activityOptions[index] || []}
            isRequired={required}
            readOnly={!enabled}
            hidden={!visible}
            onChange={(e) =>
              updateItem({
                ...item,
                activity: e.target.value,
                description: '',
                subActivity: '',
                subDescription: '',
              })
            }
          />
        ) : null}
        {selectedActivity?.descriptionRequired ? (
          <InputField
            className="flex-1"
            label="Description"
            value={item.description || ''}
            onChange={(e) => updateItem({ ...item, description: e.target.value })}
            isRequired={required}
            readOnly={!enabled}
            hidden={!visible}
          />
        ) : null}
        {subActivities?.length ? (
          <SelectField
            className="flex-1"
            label="Sub-Activity"
            value={item.subActivity}
            options={subActivities || []}
            isRequired={required}
            readOnly={!enabled}
            hidden={!visible}
            onChange={(e) =>
              updateItem({
                ...item,
                subActivity: e.target.value,
                subDescription: '',
              })
            }
          />
        ) : null}
        {subActivityDescriptionRequired ? (
          <InputField
            className="flex-1"
            label="Description"
            value={item.subDescription || ''}
            onChange={(e) => updateItem({ ...item, subDescription: e.target.value })}
            isRequired={required}
            readOnly={!enabled}
            hidden={!visible}
          />
        ) : null}
      </div>
    )
  }

  return (
    <ArrayComponent
      initialData={data}
      renderItem={renderItem}
      generateNewItem={generateNewItem}
      onChange={(updatedData) => handleChange(path, updatedData)}
      buttonLabel="Add activity"
      errors={errors}
    />
  )
}

const CountryControlWithJsonForms = withJsonFormsControlProps(ActivityControl)
export default CountryControlWithJsonForms
