import { ReactNode, useState } from 'react'
import { MdAdd, MdDelete } from 'react-icons/md'

import { IconButton, OutlineButton } from 'src/components/Buttons'

import { ErrorsLabel } from './ErrorsLabel'

// Temporary solution: This component is used as a placeholder until proper custom array control support is implemented.
// Once support becomes available, refactor any components relying on this to use the updated functionality.

interface ArrayComponentProps<T> {
  initialData: T[]
  renderItem: (item: T, index: number, updateItem: (newItem: T) => void) => ReactNode
  generateNewItem: () => T
  label?: string
  buttonLabel?: string
  onChange?: (data: T[]) => void
  errors: string
}

const ArrayComponent = <T extends {}>({
  initialData = [],
  renderItem,
  generateNewItem,
  label = '',
  buttonLabel = 'Add Item',
  onChange,
  errors,
}: ArrayComponentProps<T>) => {
  const [data, setData] = useState<T[]>(initialData)

  const handleAdd = () => {
    const newItem = generateNewItem()
    const updatedData = [...data, newItem]
    setData(updatedData)
    onChange?.(updatedData)
  }

  const handleRemove = (index: number) => {
    const updatedData = data.filter((_, i) => i !== index)
    setData(updatedData)
    onChange?.(updatedData)
  }

  const updateItem = (index: number, newItem: T) => {
    const updatedData = data.map((item, i) => (i === index ? newItem : item))
    setData(updatedData)
    onChange?.(updatedData)
  }

  return (
    <>
      <ErrorsLabel errors={errors} />
      {label && <h1 className="text-base/7 font-semibold text-gray-900 uppercase">{label}</h1>}
      {data?.map((item, index) => (
        <div key={index} className="flex items-center gap-4 py-2">
          <div className="flex-1">{renderItem(item, index, (newItem) => updateItem(index, newItem))}</div>
          <IconButton className="w-8 h-8" onClick={() => handleRemove(index)} icon={MdDelete} />
        </div>
      ))}
      <div className="pt-4 flex justify-center">
        <OutlineButton onClick={handleAdd} icon={MdAdd}>
          {buttonLabel}
        </OutlineButton>
      </div>
    </>
  )
}

export default ArrayComponent
