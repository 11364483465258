import { createAjv } from '@jsonforms/core'
import { materialCells, materialRenderers } from '@jsonforms/material-renderers'
import { JsonForms } from '@jsonforms/react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { FC, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useGetSurvey, useUpdateSurvey } from 'src/api'
import { cells, renderers } from 'src/components/JsonForms/renderers'
import LoaderWrapper from 'src/components/LoaderWrapper'
import schema from 'src/components/Surveys/WasteHandler/schema.json'
import uischema from 'src/components/Surveys/WasteHandler/uischema.json'

const classes = {
  container: {
    padding: '1em',
    width: '100%',
  },
  title: {
    textAlign: 'center',
    padding: '0.25em',
  },
  dataContent: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '0.25em',
    backgroundColor: '#cecece',
    marginBottom: '1rem',
  },
  resetButton: {
    margin: 'auto !important',
    display: 'block !important',
  },
  demoform: {
    margin: 'auto',
    padding: '1rem',
  },
}

// TODO: Move to Surveys directory
export const WasteHandlerSurvey: FC = () => {
  const { surveyId } = useParams<{ surveyId: string }>()
  const surveyQuery = useGetSurvey(surveyId)
  const updateSurveyQuery = useUpdateSurvey(surveyId)
  const handleSubmit = (data) => {
    updateSurveyQuery.mutateAsync({ data })
  }
  return (
    <LoaderWrapper query={surveyQuery}>
      {() => <WasteHandlerFormWithDebug initialData={surveyQuery.data?.data} onSubmit={handleSubmit} />}
    </LoaderWrapper>
  )
}

function WasteHandlerFormWithDebug({ initialData, onSubmit }) {
  const [data, setData] = useState<object>(initialData)
  const stringifiedData = useMemo(() => JSON.stringify(data, null, 2), [data])
  const handleChange = ({ data }) => {
    setData(data)
  }
  const clearData = () => {
    setData({})
  }

  const ajv = createAjv({ strict: true })

  return (
    <Grid container justifyContent={'center'} spacing={1} style={classes.container}>
      <Grid item sm={6}>
        <Typography variant={'h4'}>Rendered form</Typography>
        <div style={classes.demoform}>
          <JsonForms
            schema={schema}
            uischema={uischema}
            data={data}
            renderers={[...renderers, ...materialRenderers]}
            cells={[...cells, ...materialCells]}
            onChange={handleChange}
            ajv={ajv}
          />
        </div>
        <Button variant="contained" color="primary" onClick={() => onSubmit(data)}>
          Submit
        </Button>
      </Grid>
      <Grid item sm={4}>
        <Typography variant={'h4'}>Bound data</Typography>
        <div style={classes.dataContent}>
          <pre id="boundData">{stringifiedData}</pre>
        </div>
        <Button
          style={classes.resetButton}
          onClick={clearData}
          color="primary"
          variant="contained"
          data-testid="clear-data"
        >
          Clear data
        </Button>
      </Grid>
    </Grid>
  )
}
