import classNames from 'classnames'

interface ButtonGroupProps {
  value: string
  options: { id: string; name: string }[]
  onSelectedChanged?: (id: string) => void
  readOnly?: boolean
}

export function ButtonGroup({ value, options, onSelectedChanged, readOnly }: ButtonGroupProps) {
  return (
    <div className="inline-flex rounded-md shadow-sm" role="group">
      {options.map((opt, i) => {
        const isSelected = opt.id === value
        // When the group is read-only, just show the select value, instead of all the options
        if (readOnly && !isSelected) {
          return null
        }
        const first = i === 0 || readOnly
        const last = i === options.length - 1 || readOnly

        return (
          <button
            key={opt.id}
            onClick={() => onSelectedChanged?.(opt.id)}
            type="button"
            className={classNames('flex-1 px-4 py-2 text-sm font-medium min-w-120px uppercase border-none', {
              'bg-slate-100 text-slate-600': readOnly,
              'bg-teal-500 hover:bg-teal-600 text-white': !readOnly && isSelected,
              'bg-slate-100 hover:bg-slate-300 text-slate-600': !isSelected,
              'rounded-l': first,
              'rounded-r': last,
              'border-l': !first,
            })}
            disabled={readOnly}
          >
            {opt.name}
          </button>
        )
      })}
    </div>
  )
}
