import {
  JsonFormsCellRendererRegistryEntry,
  JsonFormsRendererRegistryEntry,
  RankedTester,
  and,
  isBooleanControl,
  isEnumControl,
  isIntegerControl,
  isMultiLineControl,
  isNumberControl,
  isPrimitiveArrayControl,
  isStringControl,
  optionIs,
  rankWith,
  schemaTypeIs,
  uiTypeIs,
} from '@jsonforms/core'

import BooleanCell from 'src/components/JsonForms/cells/BooleanCell'
import CountryCell from 'src/components/JsonForms/cells/CountryCell'
import IntegerCell from 'src/components/JsonForms/cells/IntegerCell'
import LabelCell from 'src/components/JsonForms/cells/LabelCell'
import MultiLineCell from 'src/components/JsonForms/cells/MultiLineCell'
import NumberCell from 'src/components/JsonForms/cells/NumberCell'
import SelectCell from 'src/components/JsonForms/cells/SelectCell'
import TextCell from 'src/components/JsonForms/cells/TextCell'
import ArrayControlRenderer from 'src/components/JsonForms/complex/ArrayControlRenderer'
import ActivityControl from 'src/components/JsonForms/controls/ActivityControl'
import CheckboxControl from 'src/components/JsonForms/controls/CheckboxControl'
import CompositionArrayControl from 'src/components/JsonForms/controls/CompositionArrayControl'
import CountryControl from 'src/components/JsonForms/controls/CountryControl'
import InputControl from 'src/components/JsonForms/controls/InputControl'
import IntegerControl from 'src/components/JsonForms/controls/IntegerControl'
import LabelControl from 'src/components/JsonForms/controls/LabelControl'
import NumberControl from 'src/components/JsonForms/controls/NumberControl'
import RegionControl from 'src/components/JsonForms/controls/RegionControl'
import SelectControl from 'src/components/JsonForms/controls/SelectControl'
import TextAreaControl from 'src/components/JsonForms/controls/TextAreaControl'
import YesNoControl from 'src/components/JsonForms/controls/YesNoControl'
import ColumnLayout from 'src/components/JsonForms/layouts/ColumnLayout'
import GroupLayout from 'src/components/JsonForms/layouts/GroupLayout'

const DEFAULT_RANK = 100

export const groupLayoutTester = rankWith(DEFAULT_RANK, uiTypeIs('Group'))
export const columnLayoutTester = rankWith(DEFAULT_RANK, uiTypeIs('ColumnLayout'))

export const labelTester: RankedTester = rankWith(DEFAULT_RANK, uiTypeIs('Label'))
export const booleanTester = rankWith(DEFAULT_RANK, schemaTypeIs('boolean'))
export const numberTester = rankWith(DEFAULT_RANK, isNumberControl)
export const integerTester = rankWith(DEFAULT_RANK, isIntegerControl)
export const stringTester = rankWith(DEFAULT_RANK, isStringControl)
export const stringMultiLineTester = rankWith(DEFAULT_RANK, and(schemaTypeIs('string'), optionIs('multi', true)))
export const stringEnumTester = rankWith(DEFAULT_RANK, and(schemaTypeIs('string'), isEnumControl))
export const arrayControlTester: RankedTester = rankWith(DEFAULT_RANK, isPrimitiveArrayControl)

// Specific Use Case Controls
export const countryTester = rankWith(DEFAULT_RANK + 1, and(schemaTypeIs('string'), uiTypeIs('CountryControl')))
export const regionTester = rankWith(DEFAULT_RANK + 1, and(schemaTypeIs('string'), uiTypeIs('RegionControl')))

export const compositionTester = rankWith(
  DEFAULT_RANK + 1,
  and(schemaTypeIs('array'), uiTypeIs('CompositionArrayControl')),
)

export const activityTester = rankWith(DEFAULT_RANK + 1, and(schemaTypeIs('array'), uiTypeIs('ActivityArrayControl')))

export const renderers: JsonFormsRendererRegistryEntry[] = [
  { tester: groupLayoutTester, renderer: GroupLayout },
  { tester: columnLayoutTester, renderer: ColumnLayout },
  { tester: arrayControlTester, renderer: ArrayControlRenderer },
  { tester: labelTester, renderer: LabelControl },
  { tester: booleanTester, renderer: YesNoControl },
  { tester: booleanTester, renderer: CheckboxControl },
  { tester: numberTester, renderer: NumberControl },
  { tester: integerTester, renderer: IntegerControl },
  { tester: stringEnumTester, renderer: SelectControl },
  { tester: stringMultiLineTester, renderer: TextAreaControl },
  { tester: stringTester, renderer: InputControl },
  { tester: countryTester, renderer: CountryControl },
  { tester: regionTester, renderer: RegionControl },
  { tester: compositionTester, renderer: CompositionArrayControl },
  { tester: activityTester, renderer: ActivityControl },
]

// Cells (renderers used for components in list etc.)
export const labelCellTester: RankedTester = rankWith(DEFAULT_RANK, uiTypeIs('Label'))
export const textCellTester: RankedTester = rankWith(DEFAULT_RANK, isStringControl)
export const integerCellTester: RankedTester = rankWith(DEFAULT_RANK, isIntegerControl)
export const numberCellTester: RankedTester = rankWith(DEFAULT_RANK, isNumberControl)
export const booleanCellTester: RankedTester = rankWith(DEFAULT_RANK, isBooleanControl)
export const multiLineCellTester: RankedTester = rankWith(DEFAULT_RANK + 1, isMultiLineControl)
// custom cells
export const countryCellTester: RankedTester = rankWith(
  DEFAULT_RANK,
  and(schemaTypeIs('string'), optionIs('country', true)),
)

export const cells: JsonFormsCellRendererRegistryEntry[] = [
  { tester: countryCellTester, cell: CountryCell },
  { tester: labelCellTester, cell: LabelCell },
  { tester: stringEnumTester, cell: SelectCell },
  { tester: textCellTester, cell: TextCell },
  { tester: integerCellTester, cell: IntegerCell },
  { tester: numberCellTester, cell: NumberCell },
  { tester: booleanCellTester, cell: BooleanCell },
  { tester: multiLineCellTester, cell: MultiLineCell },
]
