import { LabelProps } from '@jsonforms/core'
import { withJsonFormsLabelProps } from '@jsonforms/react'
import React from 'react'

import { LabelRenderer } from 'src/components/JsonForms/controls/LabelControl'

export function Cell(props: LabelProps) {
  return <LabelRenderer {...props} />
}

export default withJsonFormsLabelProps(Cell)
