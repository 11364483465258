import { CellProps } from '@jsonforms/core'
import { withJsonFormsCellProps } from '@jsonforms/react'
import React from 'react'

import NumberControl from 'src/components/JsonForms/controls/NumberControl'

export function Cell(props: CellProps) {
  return <NumberControl {...props} />
}

export default withJsonFormsCellProps(Cell)
