import { CellProps } from '@jsonforms/core'
import { withJsonFormsCellProps } from '@jsonforms/react'
import React from 'react'

import SelectControl from 'src/components/JsonForms/controls/SelectControl'

export function Cell(props: CellProps) {
  return <SelectControl {...props} />
}

export default withJsonFormsCellProps(Cell)
