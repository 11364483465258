import { ControlProps } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'

import { TextAreaField } from 'src/components/Fields'
import { ErrorsLabel } from 'src/components/JsonForms/controls/ErrorsLabel'

const TextAreaControl = ({
  data,
  label,
  path,
  schema,
  required,
  enabled,
  visible,
  handleChange,
  errors,
}: ControlProps) => (
  <div>
    <TextAreaField
      type={schema.format}
      label={label}
      value={data}
      isRequired={required}
      readOnly={!enabled}
      hidden={!visible}
      onChange={(e) => handleChange(path, e.target.value)}
      hasError={!!errors}
    />
    <ErrorsLabel errors={errors} />
  </div>
)

const TextAreaControlWithJsonForms = withJsonFormsControlProps(TextAreaControl)
export default TextAreaControlWithJsonForms
