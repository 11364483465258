import { withJsonFormsControlProps } from '@jsonforms/react'

import { InputField } from 'src/components/Fields'

interface ControlProps {
  data: number
  label: string
  path: string
  required?: boolean
  enabled?: boolean
  visible?: boolean
  handleChange(path: string, value: number): void
}

const Control = ({ data, label, path, required, enabled, visible, handleChange }: ControlProps) => (
  <InputField
    type="number"
    label={label}
    value={data}
    readOnly={!enabled}
    isRequired={required}
    hidden={!visible}
    onChange={(e) => handleChange(path, parseFloat(e.target.value))}
  />
)

const ControlWithJsonForms = withJsonFormsControlProps(Control)
export default ControlWithJsonForms
