import dayjs from 'dayjs'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/ja'
import 'dayjs/locale/tr'
import 'dayjs/locale/zh'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import minMax from 'dayjs/plugin/minMax'
import utc from 'dayjs/plugin/utc'
import { PostHogConfig } from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import App from './App'
import { queryConfig } from './api'
import { AuthProvider, configureAuth } from './auth'
import CenterSpinner from './components/CenterSpinner'
import ReportingErrorBoundary from './errors/ReportingErrorBoundary'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'
import { getAppRootElement } from './utils'

dayjs.extend(minMax)
dayjs.extend(isBetween)
dayjs.extend(utc)
dayjs.extend(customParseFormat)

configureAuth()

const queryClient = new QueryClient({ defaultOptions: { queries: queryConfig } })

const posthogOptions: Partial<PostHogConfig> = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

ReactDOM.render(
  <StrictMode>
    <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={posthogOptions}>
      <ReportingErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Suspense fallback={<CenterSpinner />}>
              <App />
            </Suspense>
          </AuthProvider>
        </QueryClientProvider>
      </ReportingErrorBoundary>
    </PostHogProvider>
  </StrictMode>,
  getAppRootElement(),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

function sendToGoogleAnalytics({ name, delta, id }) {
  window.gtag('event', name, {
    event_category: 'Web Vitals',
    // The `id` value will be unique to the current page load
    event_label: id,
    // Google Analytics metrics must be integers, so the value is rounded.
    // For CLS the value is first multiplied by 1000 for greater precision
    // (note: increase the multiplier for greater precision if needed).
    value: Math.round(name === 'CLS' ? delta * 1000 : delta),
    // Use a non-interaction event to avoid affecting bounce rate.
    non_interaction: true,

    // OPTIONAL: any additional params or debug info here.
    // See: https://web.dev/debug-web-vitals-in-the-field/
    // metric_rating: 'good' | 'ni' | 'poor',
    // debug_info: '...',
    // ...
  })
}

reportWebVitals(sendToGoogleAnalytics)
