import { MaterialLayoutRenderer } from '@jsonforms/material-renderers'
import { withJsonFormsLayoutProps } from '@jsonforms/react'

interface LayoutProps {
  label?: string
  [x: string]: any
}

const Layout = ({ label, ...props }: LayoutProps) => {
  return (
    <div className="py-6">
      <h1 className="text-base/7 font-semibold text-gray-900 uppercase">{label}</h1>
      <div className="pl-4 border-l border-gray-900/10">
        <MaterialLayoutRenderer {...props} elements={props.uischema.elements} direction="column" />
      </div>
    </div>
  )
}

const LayoutWithJsonForms = withJsonFormsLayoutProps(Layout)
export default LayoutWithJsonForms
