import { ControlProps, resolveData } from '@jsonforms/core'
import { useJsonForms, withJsonFormsControlProps } from '@jsonforms/react'
import { useMemo } from 'react'

import { SelectField } from 'src/components/Fields'
import { ErrorsLabel } from 'src/components/JsonForms/controls/ErrorsLabel'
import { useClassifierOptionList, useGetClassifierSubOptions } from 'src/hooks'

const RegionControl = ({
  data,
  label,
  path,
  schema,
  required,
  enabled,
  visible,
  uischema,
  handleChange,
  errors,
}: ControlProps) => {
  const { core } = useJsonForms()
  const countryPath = uischema.options?.countryPath || path.replace('.region', '.country')
  const selectedCountryId = resolveData(core?.data, countryPath)

  const countries = useClassifierOptionList('countries')
  const getCountrySubdivisions = useGetClassifierSubOptions('countries', 'id', 'name', 'subdivisions', 'id', 'name')

  const country = useMemo(() => countries?.find((c) => c.id === selectedCountryId), [countries, selectedCountryId])
  const subdivisions = country ? getCountrySubdivisions(country.id) : []

  return (
    <SelectField
      label={label ?? schema.title ?? 'Region'}
      value={data ?? ''}
      options={subdivisions ?? []}
      isRequired={required}
      readOnly={!enabled}
      hidden={!visible}
      onChange={(e) => handleChange(path, e.target.value)}
      hasError={!!errors}
    >
      <ErrorsLabel errors={errors} />
    </SelectField>
  )
}

const RegionControlWithJsonForms = withJsonFormsControlProps(RegionControl)
export default RegionControlWithJsonForms
