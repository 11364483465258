import { useNavigate } from 'react-router-dom'

import { useCreateSurvey } from 'src/api'
import { Button } from 'src/components/Buttons'

export function WasteHandlerSurveyLandingPage() {
  const navigate = useNavigate()
  const { mutateAsync: createSurvey } = useCreateSurvey('waste-handler-survey', (surveyId) => {
    navigate(surveyId)
  })
  return (
    <div className="m-6 space-y-2">
      <h1>This is the Waste Handler Survey landing page</h1>
      <Button onClick={() => createSurvey()}>New Survey</Button>
    </div>
  )
}
