import { LabelProps } from '@jsonforms/core'
import { withJsonFormsLabelProps } from '@jsonforms/react'

import { FieldLabel } from 'src/components/Fields'

export function LabelRenderer({ text, visible }: LabelProps) {
  return !visible ? null : <FieldLabel>{text}</FieldLabel>
}

const Label = withJsonFormsLabelProps(LabelRenderer)
export default Label
