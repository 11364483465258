import { ControlProps } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { useEffect, useState } from 'react'

import { InputField, SelectField } from 'src/components/Fields'
import ArrayComponent from 'src/components/JsonForms/controls/ArrayComponent'
import { COMPOSITION_GROUP_FILTER, useClassifierOptionList, useGetClassifierSubOptions } from 'src/hooks'

interface Composition {
  group: string
  detail: string
  share: number
}

// Note: This component will need refactoring when custom array control support is implemented.
const CompositionArrayControl = ({ data, path, required, enabled, visible, handleChange, errors }: ControlProps) => {
  const compositionGroups = useClassifierOptionList('profileProductionCompositionGroups')
  const getProfileProductionCompositions = useGetClassifierSubOptions(
    'profileProductionCompositionGroups',
    'id',
    'name',
    'profileProductionCompositions',
    'id',
    'name',
    COMPOSITION_GROUP_FILTER,
  )

  const [compositionOptions, setCompositionOptions] = useState({})

  useEffect(() => {
    const newCompositionOptions = {}
    data?.forEach((item: Composition, index: number) =>
      item.group ? (newCompositionOptions[index] = getProfileProductionCompositions(item.group) || []) : [],
    )
    setCompositionOptions(newCompositionOptions)
  }, [data, getProfileProductionCompositions])

  const generateNewItem = (): Composition => ({
    group: '',
    detail: '',
    share: 0,
  })

  const renderItem = (item: Composition, index: number, updateItem: (newItem: Composition) => void) => (
    <div className="flex items-end gap-4 w-full">
      <SelectField
        className="flex-1"
        label={'Group'}
        value={item.group}
        options={compositionGroups || []}
        isRequired={required}
        readOnly={!enabled}
        hidden={!visible}
        onChange={(e) => updateItem({ ...item, group: e.target.value, detail: '' })}
      />
      <SelectField
        className="flex-1"
        label={'Composition'}
        value={item.detail}
        options={compositionOptions[index] || []}
        isRequired={required}
        readOnly={!enabled}
        hidden={!visible}
        onChange={(e) => updateItem({ ...item, detail: e.target.value })}
      />
      <InputField
        label={'Share %'}
        type="number"
        value={item.share || ''}
        onChange={(e) => updateItem({ ...item, share: Number(e.target.value) })}
        isRequired={required}
        readOnly={!enabled}
        hidden={!visible}
        className="max-w-80px"
      />
    </div>
  )
  return (
    <ArrayComponent
      initialData={data}
      renderItem={renderItem}
      generateNewItem={generateNewItem}
      onChange={(updatedData) => handleChange(path, updatedData)}
      buttonLabel="Add Composition"
      errors={errors}
    />
  )
}

const CountryControlWithJsonForms = withJsonFormsControlProps(CompositionArrayControl)
export default CountryControlWithJsonForms
