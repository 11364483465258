import dayjs from 'dayjs'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Fetch from 'i18next-fetch-backend'
import { initReactI18next } from 'react-i18next'

import { getUrl } from './api/query'
import locales from './locales.json'

i18n
  .use(LanguageDetector)
  .use(Fetch)
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    saveMissing: process.env.REACT_APP_SAVE_MISSING_TRANSLATIONS === 'true',
    saveMissingTo: 'current',
    // parseMissingKeyHandler: (key) => `[[${key}]]`, // broken, doesn't work with fallback languages
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      addPath: getUrl('v1/translations/') + '{{lng}}/{{ns}}/',
      stringify: JSON.stringify,
      requestOptions: {
        mode: 'cors',
        credentials: 'same-origin',
        cache: 'default',
      },
    },
    keySeparator: '`',
    nsSeparator: false,
    cleanCode: true,
    debug: process.env.REACT_APP_DEBUG_TRANSLATIONS === 'true',
    supportedLngs: ['en', 'zh', 'ja', 'fr', 'tr', 'es'],
    fallbackLng: 'en',
    ns: ['common', 'error', 'home'], // preloaded namespaces
    defaultNS: 'common',
    fallbackNS: ['common'],
    interpolation: { escapeValue: false }, // not needed for react as it escapes by default
    returnEmptyString: false,
    // resources: {}, // disabled to enable loading via backends
  })

Object.entries(locales).forEach(([code, content]) => {
  i18n.addResourceBundle(code, 'locale', content)
})

const DATE_MONTH_YEAR = 'MMMM, YYYY'
const DATE_HUGE = 'MMMM DD, YYYY'
const DATE_TIME_HUGE = 'MMMM DD, YYYY hh:mm A (Z)'
const DATE_YEAR_MONTH_NUMERIC = 'YYYY-MM'

i18n.services?.formatter?.add('DATE_MONTH_YEAR', (value, lng, options) =>
  dayjs(value)
    .locale(lng || i18n.languages[0])
    .format(DATE_MONTH_YEAR),
)
i18n.services?.formatter?.add('DATE_HUGE', (value, lng, options) =>
  dayjs(value)
    .locale(lng || i18n.languages[0])
    .format(DATE_HUGE),
)
i18n.services?.formatter?.add('DATE_TIME_HUGE', (value, lng, options) =>
  dayjs(value)
    .locale(lng || i18n.languages[0])
    .format(DATE_TIME_HUGE),
)
i18n.services?.formatter?.add('DATE_YEAR_MONTH_NUMERIC', (value, lng, options) =>
  dayjs(value)
    .locale(lng || i18n.languages[0])
    .format(DATE_YEAR_MONTH_NUMERIC),
)

const VOLUME_FORMATTER_OPTIONS = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
}

i18n.services?.formatter?.add('NUMBER_VOLUME', (value, lng, options) => {
  const isSigned = options.context === 'SIGNED' && value > 0
  return (
    (isSigned ? '+' : '') + new Intl.NumberFormat(lng || i18n.languages[0], VOLUME_FORMATTER_OPTIONS).format(value || 0)
  )
})

i18n.services?.formatter?.add('TOLOWERCASE', (value, lng, options) => {
  return value.toLowerCase()
})

// for debugging
i18n.services?.formatter?.add('STRINGIFY', (value, lng, options) => JSON.stringify(value))

export default i18n
