import { CellProps } from '@jsonforms/core'
import { withJsonFormsCellProps } from '@jsonforms/react'
import React from 'react'

import InputControl from 'src/components/JsonForms/controls/InputControl'

export function Cell(props: CellProps) {
  return <InputControl {...props} />
}

export default withJsonFormsCellProps(Cell)
