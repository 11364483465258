import { ControlProps } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'

import { SelectField } from 'src/components/Fields'
import { ErrorsLabel } from 'src/components/JsonForms/controls/ErrorsLabel'

const SelectControl = ({
  data,
  label,
  path,
  schema,
  required,
  enabled,
  visible,
  handleChange,
  errors,
}: ControlProps) => (
  <SelectField
    label={label}
    value={data}
    options={schema.enum?.map((v) => ({ id: v, name: v }))}
    isRequired={required}
    readOnly={!enabled}
    hidden={!visible}
    onChange={(e) => handleChange(path, e.target.value)}
  >
    <ErrorsLabel errors={errors} />
  </SelectField>
)

const SelectControlWithJsonForms = withJsonFormsControlProps(SelectControl)
export default SelectControlWithJsonForms
