import { ControlProps } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'

import { SelectField } from 'src/components/Fields'
import { ErrorsLabel } from 'src/components/JsonForms/controls/ErrorsLabel'
import { useClassifierOptionList } from 'src/hooks'

const CountryControl = ({
  data,
  label,
  path,
  schema,
  required,
  enabled,
  visible,
  uischema,
  handleChange,
  errors,
}: ControlProps) => {
  const countries = useClassifierOptionList('countries')
  const regionPath = uischema.options?.regionPath as string

  const onChange = (e) => {
    handleChange(path, e.target.value)
    if (uischema.options?.hasRegion) {
      handleChange(regionPath, '')
    }
  }

  return (
    <SelectField
      label={label ?? schema.title ?? 'Country'}
      value={data ?? ''}
      options={countries ?? []}
      isRequired={required}
      readOnly={!enabled}
      hidden={!visible}
      onChange={onChange}
      hasError={!!errors}
    >
      <ErrorsLabel errors={errors} />
    </SelectField>
  )
}

const CountryControlWithJsonForms = withJsonFormsControlProps(CountryControl)
export default CountryControlWithJsonForms
