import { ControlProps } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'

import { InputField } from 'src/components/Fields'
import { ErrorsLabel } from 'src/components/JsonForms/controls/ErrorsLabel'

const Control = ({ data, label, path, required, enabled, visible, errors, handleChange }: ControlProps) => (
  <InputField
    type="number"
    label={label}
    value={data}
    readOnly={!enabled}
    isRequired={required}
    hidden={!visible}
    onChange={(e) => handleChange(path, parseInt(e.target.value))}
    hasError={!!errors}
  >
    <ErrorsLabel errors={errors} />
  </InputField>
)

const ControlWithJsonForms = withJsonFormsControlProps(Control)
export default ControlWithJsonForms
